.confirmModel_M .ant-modal-body {
  background-color: rgb(30, 30, 30);
}

.notesDesignPhone {
  color: white;
  padding: 20px;
  font-family: inherit;
  text-align: justify;
}

.underlineBtn:hover {
  text-decoration: underline;
  color: white;

}
.underlineBtn{
    color: white;
    cursor: pointer;
}
.underline-Cbtn {
  display: flex;
  padding: 20px;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}
.confirmModel_M .ant-modal-header,.confirmModel_M .ant-modal-footer{
    display: none;
    height: 0px;
}

.confirmModel_M .ant-modal-close-x{
    display: none
}

.ant-modal-content .header.build.innner_idv{
  width: 98%;
  margin: auto;
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
}

.ant-modal-content .ant-modal-body .innerItembuild{
  width: 98%;
  margin: auto;
  border-bottom-right-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}

.ant-modal.confirmModel_M .ant-modal-content .ant-modal-body{
  padding-top: 5px;
  border-radius: .30em;
}