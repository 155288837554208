.topSection_h {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1%;
  background: #f5f5f5;
  height: 65px;

}
.m_header {
  position: fixed;
  width: 100%;
  z-index: 77;
}
.c_header {
  width: 100%;
  height: 65px
}
.left_Headtop {
  font-family: "Libre Caslon Display", serif;
  color: rgb(88, 88, 88);
  letter-spacing: 0.16em;
  padding-left: 25px;
  font-size: 1.6rem;
  font-weight: 500;
  opacity: 1;
  animation: flickerAnimation 1s linear;
}
.right_Headtop {
  letter-spacing: 0.16em;
  font-size: 1.3rem;
  line-height: 21px;
  display:block;
  

  text-transform: uppercase;
  margin-right: 20px;
  margin-top: 0px;
  padding-bottom: 0%;
  margin-bottom: 0px;
  color: rgb(29, 28, 28);
  font-family: "Libre Caslon Display", serif;
  opacity: 1;
  animation: flickerAnimation 1s linear;
}
.bottomRight_Headtop {
  letter-spacing: 0.16em;
  font-size: 1.3rem;
  line-height: 21px;
  display:inline-block;
  

  text-transform: uppercase;
  margin-right: 20px;
  margin-top: 0px;
  padding-top: 0%;
  margin-bottom: 0px;
  color: rgb(29, 28, 28);
  font-family: "Libre Caslon Display", serif;
  opacity: 1;
  animation: flickerAnimation 1s linear;
}

.address2titleas {
  font-size: 24px;
  margin: 0px;
  font-family: "Libre Caslon Display", serif;
  margin-top: 1px;
  color: rgb(88, 88, 88);
  letter-spacing: 3px;
  opacity: 1;
  padding-left: 10px;

  animation: flickerAnimation 1s linear;
}
.address2screensub {
  font-size: 14px;
  margin: 0px;
  padding-left: 10px;

  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  margin-top: 2px;
  color: rgb(88, 88, 88);
  letter-spacing: 1px;
  opacity: 1;
  animation: flickerAnimation 1s linear;
}
@keyframes flickerAnimation {
  /* flame pulses */
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 800px) {
  .left_Headtop {
    font-size: 0.9rem;
    width: 45%;
  }
  .right_Headtop {
    font-size: 0.8rem;
  }
  .bottomRight_Headtop {
    font-size: 0.8rem;
  }
  .address2titleas {
    font-size: 14px;
    letter-spacing: 2px;
  }
  .address2screensub {
    font-size: 10px;
  }
}

.bar {
  width: 100%;
  height: 3px;
  background: #ffff;
}
.bar-thumb {
  height: 3px;
  background: rgb(23, 126, 121);
}
.c_header .ant-progress-text {
  display: none;
}
.c_header .ant-progress-outer {
  padding-right: 0;
}
.c_header .ant-progress {
  position: relative;
  top: -14px;
}
.c_header .ant-progress-inner {
  border-radius: 0px;
  background-color: #f5f5f5;
  height: 6px;
}
.c_header .ant-progress-bg {
  border-radius: 0px;
  height: 6px;
}
