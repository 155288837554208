@media screen and (max-width: 800px) {
  .addressfrom {
    width: 90%;
  }

  .title-2address {
    font-size: 1.3rem;
  }
  .subtitle-2address {
    width: 80%;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.14rem;
  }
  .heardabout{
    margin-top: 20px;
  }
  .form-label-a {
    font-size: 0.75rem;
    font-weight: 400;
    width: 100%;
    line-height: 1.14rem;
  }
}
.slectionarea{
  width: 100%;
  display: flex;
  justify-content: center;
}