.Slider-Ad {
  text-align: center;
  line-height: 2.8435rem;
  margin-bottom: 10px;
  font-weight: 300;
  position: relative;
  top: -26px;
  text-align: center;
  font-size: 1.329rem;
  color: rgb(158, 158, 158);
  font-family: "Avenir Next", Helvetica, Arial, sans-serif;
}
.Slider-h {
  line-height: 2.8435rem;
  margin-bottom: 40px;
}
.Slider-p {
  color: rgb(66, 66, 66);
}
.Slider_info {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.slider-con {
  display: flex;
  flex-direction: column;
  width: 85%;
}
.bootom_limit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.bootom_limit p {
  width: 30px;
  margin-top: 10px;
  font-weight: 500;

  letter-spacing: 0.16em;
}
.slider-con .ant-slider-step {
  position: absolute;
  width: 100%;
  height: 1px;
  background: rgb(196, 196, 196);
}

.slider-con .ant-slider-step:hover {
  position: absolute;
  width: 100%;
  height: 1px;
  outline-width: 0;
}
.ant-slider-track {
  position: absolute;
  height: 3px;
  background-color: black;
  border-radius: 2px;
  outline-style: unset;
  outline-offset: 0 !important;
  outline-color: white;
  box-shadow: unset;
}

.slider-con .ant-slider-track:hover,
.slider-con .ant-slider-track:focus,
.slider-con .ant-slider-track:active {
  position: absolute;
  height: 3px;
  background-color: unset !important;
  border-radius: 2px;
  outline-width: 0;
  background-color: black;
  outline-style: unset;
  outline-offset: 0 !important;
  outline-color: white;
  box-shadow: white;
}

.slider-con .ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: black;
  border: solid 2px black;
}

.slider-con .ant-slider-handle:hover,
.slider-con .ant-slider-handle:active,
.slider-con .ant-slider-handle:focus {
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: black;
  border: solid 2px black;
  outline-color: black;
  box-shadow: unset;
}
.ant-slider {
  box-shadow: unset;
  outline-width: 0;
}

.ant-slider:hover {
  box-shadow: unset;
  outline-width: 0;
}

.ant-slider-rail {
  height: 0;
}
.ant-slider-track:hover {
  width: 2px;
  background-color: black;
}
.ant-slider:hover .ant-slider-track {
  background-color: black;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: black;
}
.inputNum {
  border-color: rgb(250, 250, 250) 2px solid;
  background-color: rgb(250, 250, 250);
  border: 0;
  width: 200px;
  text-align: center;
  color: black;
  line-height:  2.8435rem;
  font-weight: 500;
  font-size: 18px;
  padding-top: 20px;
  font-weight: bolder;
  text-align: center;
  font-family: inherit;
  height: 45px;
  text-align: center;
  font-size: 1.7rem;
  position: relative;
  top: -35px;
  font-weight: 600;
}

.inputNum:hover,
.inputNum:active,
.inputNum:focus {
  border: 0px;
  outline-width: 0;
  outline-color: white;
}

.inputNum .ant-input-number-handler-wrap {
  position: relative;
  left: -421px;
}
.inputNum .ant-input-number-focused {
  border-color: rgb(196, 196, 196) !important;
  outline-color: unset;
  outline-width: 0;
}

.inputNum.ant-input[disabled] {
   /* border-color: rgb(250, 250, 250) 2px solid;*/
    background-color: #f5f5f5;
    border: 0;
    width: 200px;
    text-align: center;
    color: black;
    line-height:  2.8435rem;
    font-weight: 500;
    font-size: 18px;
    padding-top: 20px;
    font-weight: bolder;
    text-align: center;
    font-family: inherit;
    height: 45px;
    text-align: center;
    font-size: 1.7rem;
    position: relative;
    top: -35px;
    font-weight: 600;
}
