.first_f {
  display: flex;
  height: 87%;
  width: 100%;
  align-items: center;
  position: relative;
  top: -14vh;
  justify-content: center;
  flex-direction: column;
}
.text_title {
  text-align: center;
  font-size: 32px;
  margin-top: 50px;
  margin-bottom: 10px;
  color: rgb(30, 30, 30);
  font-weight: 500;
  font-family: "Libre Caslon Display", serif;
}
.asbtnblack{
  border: 1.3px solid  rgb(30, 30, 30) !important;
}
.text_sub {
  width: 70%;
  text-align: center;
  font-size: 12px;
  color: rgb(53, 52, 50);
  font-family: "Avenir Next", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.714rem;
}
.text-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.form_c {
  width: 730px;
  margin: auto;
  min-height: 200px;
  /* margin-top: 30px; */
  border-radius: .25rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f5f5f5;
}
.title-c.myforst {
  text-align: center;
  font-size: 34px;
  margin-top: 50px;
  padding-bottom: 40px;
  
  line-height: 1.1435rem;
  color: rgb(30, 30, 30);
  font-weight: 500;
  font-family: "Libre Caslon Display", serif;
}
.btn_div {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 647px;
  margin-bottom: 30px;
}

.btn_div button,
.btn_div .selectCommerial {
  color: rgb(25, 25, 25);
  border: 1px solid rgb(190, 190, 190);
  padding: 10px;
  width: calc(50% - 10px);
  height: 100px;
  background: white;
  margin-top: 10px;
  cursor: pointer;
  overflow: unset;
  text-align: center;
  color: rgb(34, 34, 34);
  font-family: "Avenir Next", Helvetica, Arial, sans-serif;
  font-size: 1.05rem;
  font-weight: 400;
  line-height: 1.14rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.btn_div button:hover {
  text-decoration: underline;
}

.selectCommerial .ant-select-selection, 
.selectCommerial .ant-select-selection.ant-select-selection--single:focus {
  border: 0px !important;
  border-color: white;
}
.btn_div button:focus{
  border: 1px !important;
  border-color: white;
  border-style: solid;
}
.selectCommerial {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active,
.ant-select-open .ant-select-selection {
  border-color: #d9d9d9;
  box-shadow: none;
}
.selectCommerial .ant-select-arrow {
  color: black;
}
.selectCommerial .ant-select-selection-selected-value {
  margin-right: 5px;
  text-align: center !important;
}

@media screen and (max-width: 800px) {
  .form_c {
    width: 90%;
    height: 175px;
  }
  .title-c.myforst,
  .text_title {
    font-size: 1rem;
  }
  .text_sub {
    width: 80%;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1.14rem;
  }
  .btn_div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0px;
    margin: 0px;
    margin-bottom: 20px;
  }
  .btn_div button,
  .btn_div .selectCommerial {
    height: 70px;
    width: 45%;
    font-size: 0.6rem;
    overflow: unset;
    letter-spacing: 0px;
  }
  .selectCommerial {
    overflow: scroll;
  }
}
