.build-f {
  width: 100%;
  height: 87.5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buildfrom {
  width: 730px;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  padding-top: 20px;
  border-radius: .25rem;
  padding-bottom: 30px;
  background: #f5f5f5;
  overflow: auto;
  justify-content: center;
  top: 0;
  align-items: center;
  animation: mymove1 0.5s ease-out !important;
}
.buildTop-t {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.buildtitle-2 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 0px;
  font-weight: 500;
  color: black;
  font-family: "Libre Caslon Display", serif;
}
.buildsubtitle-2 {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: rgb(53, 52, 50);
  font-family: "Avenir Next", Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.214rem;
}
@keyframes mymove1 {
  from {
    top: 50px;
    height: 700px;
  }
  to {
    top: 0px;
    height: 570px;
  }
}

.intermadiaterole {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid rgb(204, 204, 204);
}
.lowerpart {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.lowerpart .btnalso {
  width: 47%;
  height: 100%;
  cursor: pointer;
}

.header {
  width: 100%;
  height: 60px;
  background: white;
  display: flex;
  padding: 20px;
  border: 0.5px solid rgb(204, 204, 204);
  justify-content: space-between;
  align-items: baseline;
}
.tilte-ban {
  font-size: 1rem;
  color: rgb(30, 30, 30);
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-family: "Avenir Next", Helvetica, Arial, sans-serif;
}
.innerItembuild {
  padding: 20px;
  text-align: left !important;
  background: white;
  border: 0.5px solid rgb(204, 204, 204);
  border-top: 0px;
  color: black;
  height: 160px;
}
.btncon {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.hieghtequal {
  width: 100%;
}
