.btn_divform {
  width: 93% !important;
  margin-top: 25px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.withoutMargin {
  margin-top: 0px;
}
.btnform2:hover,
.btnform2:focus {
  border: 1px solid rgb(190, 190, 190);
  color: #000;
  border: 0;
  outline-width: 0;
  border-color: white;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px white;
  box-shadow: 0 0 0 2px white;
}
.btnform2 {
  width: 48%;
  border-radius: 0px;
  height: 63px;
  background: white;
  text-align: center;
}
.btnform2.a2 {
  white-space: nowrap;
  font-family: "Avenir Next", Helvetica, Arial, sans-serif;
  font-size: 0.8572rem;
  font-weight: 600;
  letter-spacing: 0.16em;
  line-height: 2em;
  text-transform: uppercase;
  cursor: pointer;
  background-color: rgb(204, 204, 204);
  color: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-color: rgb(204, 204, 204);
}
.btnform2.a1 {
  white-space: nowrap;
  font-family: "Avenir Next", Helvetica, Arial, sans-serif;
  font-size: 0.8572rem;
  font-weight: 600;
  letter-spacing: 0.16em;
  line-height: 2em;
  text-transform: uppercase;
  cursor: pointer;
  background-color: white;
  color: black;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-color: rgb(204, 204, 204);
}
.backvalidate {
  background-color: rgb(30, 30, 30) !important;
}
.backvalidate:hover {
  background-color: rgb(30, 30, 30,.8) !important;;
  -webkit-box-shadow: 1px 0px 14px 2px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 1px 0px 14px 2px rgba(0, 0, 0, 0.31);
  box-shadow: 1px 0px 14px 2px rgba(0, 0, 0, 0.31);
}
