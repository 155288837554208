.main {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
}
.innerScreeenItem {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.constainer_form {
  overflow-y: hidden;
  background: url(../images/Green-Kitchen.jpg)
    center fixed;
  background-repeat: no-repeat;
  background-position-y:66px; 
  background-size: cover;
}
.sameForHeight {
  height: 100%;
  overflow-x: hidden;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
.constainer_form2-5 {
  overflow-y: scroll;
  background: url(../images/clean-bath.jpg)
    center fixed;
  background-repeat: no-repeat;
  background-position-y:16px; 
  background-size: cover;
}
.constainer_formL {
  overflow-y: hidden;
  background: url(../images/living-room.jpg)
    center fixed;
  background-repeat: no-repeat;
  background-position-y:66px; 
  background-size: cover;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: rgba(4, 5, 6, 0);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: rgb(130, 130, 130);
  border-radius: 20px;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  height: 100%;
  width: auto;
  visibility: visible;
  display: flex !important;
  max-width: 100%;
  max-height: 100%;
  align-items: center !important;
  justify-content: center !important;
}

.mycostom .simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  height: 100%;
  width: auto;
  visibility: visible;
  display: block !important;
  max-width: 100%;
  max-height: 100%;
}

#root {
  height: 100vh;
  width: 100vw;
}
