.ant-modal.mycostom .ant-modal-content {
  background-color: rgb(245, 245, 245);
  width: 579px !important;
  position: relative !important;
  left: -7%;
 /* top: -70px; */
  z-index: 33333;
}
.ant-modal-wrap {
  overflow: hidden;
}
.ant-modal-title,
.ant-modal-close-x {
  text-align: center;
  margin-top: 8px;
  text-transform: uppercase;
  font-family: "Avenir Next", Helvetica, Arial, sans-serif;
  letter-spacing: 3px;
  font-weight: 300;
  font-size: 1.429rem;
  color: rgb(34, 34, 34);
}
.ant-modal-header {
  height: 65px;
  background-color: inherit;
}
.headingbuild {
  text-align: center;
  color: black;
  font-weight: 500;
  line-height: 1.714rem;
  font-size: 18px;
  padding-top: 6px;
  font-family: inherit;
}
.small-msg {
  text-align: center;
  font-size: 12px;
  line-height: 1.214rem;
  color: rgb(88, 86, 82);
  font-family: inherit;
}

.checkboxcon {
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ant-checkbox-wrapper {
  width: 100%;
  height: 90px;
  background-color: white;
  margin-top: 20px;
  display: flex;
  border-color: 1px solid rgb(202, 202, 202);
  align-items: center;
  justify-content: center;
  border-width: 0.5px;
  border-style: solid;
  border-image: initial;
  border-color: rgb(204, 204, 204);
}
.ant-checkbox-wrapper .optionMain,
.radioCont .optionMain {
  font-size: 16px;
  font-family: inherit;
  color: rgb(30, 30, 30);
  letter-spacing: 0.16em;
  margin-bottom: 0px;
  text-align: center;
  text-transform: uppercase;
}
.optionNot {
  text-align: center !important;
  font-size: 12px;
  color: rgb(134, 132, 126);
  font-family: inherit;
  line-height: 16px;
  margin-top: 10px;
  /* width: 90%; */
  display: flex;
  text-overflow: ellipsis;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.ant-checkbox-wrapper-checked,
.ant-checkbox-wrapper:hover {
  border: rgb(30, 30, 30) 1.4px solid;
}

.ant-checkbox {
  position: absolute;
  z-index: -30;
  top: -3333px;
}

.btnbuild {
  width: 100%;
  border-radius: 0px;
  height: 100%;
  height: 50px;
  background: white;
  text-align: center;
  margin: auto;
}
.ant-modal-footer {
  text-align: center;
}
.btnbuild.a2 {
  white-space: nowrap;
  font-family: "Avenir Next", Helvetica, Arial, sans-serif;
  font-size: 0.8572rem;
  font-weight: 600;
  letter-spacing: 0.16em;
  line-height: 2em;
  text-transform: uppercase;
  cursor: pointer;
  background-color: rgb(204, 204, 204);
  color: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-color: rgb(204, 204, 204);
}

.renoinfo {
  text-decoration: underline;
  padding: 20px;
  cursor: pointer;
}
.ant-modal-body {
padding: 0px;
}
.hightincress {
  height: 500px;
  max-height: 480px !important;
}
@media screen and (max-width: 650px) {
  .ant-modal.mycostom .ant-modal-content {
    background-color: rgb(245, 245, 245);
    width: 90% !important;
    position: relative !important;
    left: 5%;
    top: 2vh;
  }
  .ant-checkbox-group,
  .radioCont {
    margin-left: 10%;
    margin-right: 10%;
  }
  .ant-checkbox-group-item {
    height: 55px;
  }
  .modalbody {
    /* max-height: 300px !important; */
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .headingbuild {
    font-size: 14px;
    padding-top: 0px;
  }

  .ant-checkbox-group-item span {
    line-height: 1.214rem;
    font-size: 12px;
  }
  .ant-modal-title,
  .ant-modal-close-x {
    font-size: 0.8rem;
  }
}

.radioCont {
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.radioCont .simpleRadioBtn {
  width: 100%;
  height: 90px;
  display: flex;
  background-color: white;
  margin-top: 20px;
  display: flex;
  padding-top: 10px;
  border-radius: 0;
  border-color: 1px solid rgb(202, 202, 202);
  align-items: center;
  justify-content: center;
  border-width: 0.5px;
  border-style: solid;
  border-image: initial;
  border-color: rgb(204, 204, 204);
}

.radioCont .sleectecd {
  z-index: 1;
  color: black;
  background: #fff;
  border: black solid 1px;
  -webkit-box-shadow: 0px 0 0 0 white;
  box-shadow: 0px 0 0 0 white;
}

.radioCont .simpleRadioBtn:hover {
  border: rgb(30, 30, 30) 1.4px solid;
  outline: unset;
  border-left: 1;
  outline-width: 0;
  box-shadow: none;
}

.radiodev-as
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: black;
  background: #fff;
  border-color: #0b141d;
  -webkit-box-shadow: -0.5px 0 0 0 #0c1013;
  box-shadow: -0.5px 0 0 0 #030608;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: black;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
