@media screen and (max-width: 800px) {
    .innerItembuild{
        padding: 10px;
        text-align: left !important;
        background: white;
        border: .5px solid rgb(204, 204, 204);
        border-top: 0px;
        height: unset;
    }
    .buildfrom{
        animation: unset;
        position: relative;
        padding-bottom: 40px;
        top:15%;
      }

    .buildfrom{
        width: 90%;
    }
    .tilte-ban {
        font-size: .8rem;
        color: rgb(30, 30, 30);
        letter-spacing: 0.1em;
    }
        .header{
            padding: 10px;
            padding-top: 15px;
        }
        .buildtitle-2 {
            text-align: center;
            font-size: 1.3rem;
            margin-bottom: 10px;
            
          }
          .buildsubtitle-2 {
            width: 80%;
            text-align: center;
            font-size: 0.7rem;
            color: black;
            line-height: 1.214rem;
          }
        
          .lowerpart {
            width: 90%;
            margin-top: 10px;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            
            flex-direction: column;
            justify-content: space-between;
          }
          .lowerpart .btnalso {
            width: 90%;
            height: 100%;
            cursor: pointer;
          }
          .lowerpart .btnalso.a{
              margin-bottom: 30px;
          }
 }
  